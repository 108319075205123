<template>
  <VContainer>
    <VRow>
      <VCol>
        <VTabsItems
          v-model="activeTab"
          vertical
          class="pb-16 mb-12"
        >
          <VTabItem
            value="#main"
            :transition="false"
          >
            <VContainer>
              <VRow>
                <VCol>
                  <TTTextField
                    v-model="title"
                    maxlength="100"
                    label="Название"
                    large
                    placeholder="Например, Основы кибербезопасности"
                    data-test-label="input-track-name"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <TTTextarea
                    v-model="description"
                    label="Описание"
                    placeholder="О чем трек, для кого будет полезен, что узнают сотрудники"
                    maxlength="500"
                    data-test-label="textarea-track-description"
                    counter
                  />
                </VCol>
              </VRow>
              <VRow no-gutters>
                <VCol>
                  <VDivider class="my-4" />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <Uploader
                    type="IMAGE"
                    :value="poster"
                    :max-file-mb="15"
                    :error="!!errorPoster"
                    :error-messages="errorPoster"
                    :show-loading="loadingPoster"
                    :abort="false"
                    :progress-indeterminate="true"
                    @selectFile="(file) => uploadPoster(file)"
                    @delete="deletePoster"
                  />
                </VCol>
              </VRow>
            </VContainer>
          </VTabItem>
          <VTabItem
            value="#content"
            :transition="false"
          >
            <EmptySection
              v-if="emptyState"
              text="Здесь появятся уровни, которые можно будет наполнить задачами."
              title="Создайте уровни"
            >
              <template #image>
                <VRow justify="center">
                  <VCol cols="auto">
                    <VRow dense>
                      <VCol cols="12">
                        <ListItemSkeleton
                          :prepend-icon="TRACK_TYPES_ICON[TRACK_TYPES.LEVEL]"
                          :prepend-icon-color="TRACK_ICON_COLOR"
                        />
                      </VCol>
                    </VRow>
                    <VRow dense>
                      <VCol cols="12">
                        <ListItemSkeleton
                          class="ml-10"
                          :prepend-icon="TASK_TYPE_ICONS[TASK_TYPES.READ]"
                          :prepend-icon-color="TASK_TYPE_ICON_COLOR"
                        />
                      </VCol>
                    </VRow>
                    <VRow dense>
                      <VCol cols="12">
                        <ListItemSkeleton
                          :prepend-icon="CONTENT_TYPE_ICONS[CONTENT_TYPES.HTML]"
                          :prepend-icon-color="CONTENT_TYPE_ICON_COLOR"
                        />
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              </template>
            </EmptySection>
            <OLevelList
              v-else
              :show-create-button="false"
            />
          </VTabItem>
          <VTabItem
            value="#appointment"
            :transition="false"
          >
            <EmptySection
              v-if="emptyState"
              title="Выберите, кому назначить трек"
              text="С помощью поиска и фильтров найдите нужных сотрудников."
            >
              <template #image>
                <VRow justify="center">
                  <VCol cols="auto">
                    <VRow dense>
                      <VCol cols="12">
                        <ListItemSkeleton>
                          <template #append>
                            <TTAvatarGroup
                              :items="userAvatars"
                              x-small
                            />
                          </template>
                        </ListItemSkeleton>
                      </VCol>
                    </VRow>
                    <VRow dense>
                      <VCol cols="12">
                        <ListItemSkeleton
                          class="ml-10"
                        >
                          <template #append>
                            <TTAvatarGroup
                              :items="userAvatars"
                              x-small
                            />
                          </template>
                        </ListItemSkeleton>
                      </VCol>
                    </VRow>
                    <VRow dense>
                      <VCol cols="12">
                        <ListItemSkeleton>
                          <template #append>
                            <TTAvatarGroup
                              :items="userAvatars"
                              x-small
                            />
                          </template>
                        </ListItemSkeleton>
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              </template>
            </EmptySection>
            <VRow v-else>
              <VCol>
                <CardWrapper
                  outlined
                  class="pa-0"
                  shadow="never"
                >
                  <VRow no-gutters>
                    <VCol cols="6">
                      <DataTransfer
                        data-test="all-track-groups"
                        data-test-prefix="all-groups"
                        :items="groups"
                        title="Все группы"
                        @click:item-action="handleClickAdd"
                      />
                    </VCol>
                    <VCol>
                      <VDivider vertical />
                    </VCol>
                    <VCol cols="6">
                      <DataTransfer
                        :items="selected"
                        title="Выбранные"
                        data-test="selected-track-groups"
                        data-test-prefix="selected-groups"
                        action-icon="fal fa-minus"
                        action-icon-color="tt-light-red"
                        @click:item-action="handleClickRemove"
                      />
                    </VCol>
                  </VRow>
                </CardWrapper>
              </VCol>
            </VRow>
          </VTabItem>
        </VTabsItems>
      </VCol>
    </VRow>
    <NavigationDrawer :title="navigationDrawerTitle" />
    <VSnackbar
      v-model="showSaveButtons"
      timeout="-1"
      light
      min-width="768"
      elevation="0"
      class="pb-9"
      data-test="track-snackbar-actions"
      content-class="card-shadow"
    >
      <VRow justify="end">
        <VCol cols="auto">
          <TTBtn
            text
            @click="handleClickChangeTrackType"
          >
            Change trackType
          </TTBtn>
        </VCol>
        <VCol cols="auto">
          <TTBtn
            text
            @click="handleClickEmptyState"
          >
            Empty State
          </TTBtn>
        </VCol>
        <template v-if="trackTypeDraft">
          <VCol cols="auto">
            <TTBtn
              text
              data-test-label="button-save-draft"
              @click="handleClickSaveDraft"
            >
              Сохранить как черновик
            </TTBtn>
          </VCol>
          <VCol cols="auto">
            <TTBtn
              data-test-label="button-publish"
              @click="handleClickPublish"
            >
              Опубликовать
            </TTBtn>
          </VCol>
        </template>
        <template v-else>
          <VCol cols="auto">
            <TTBtn
              data-test-label="button-save"
              @click="handleClickSave"
            >
              Сохранить
            </TTBtn>
          </VCol>
        </template>
      </VRow>
    </VSnackbar>
  </VContainer>
</template>

<script>
import NavigationDrawer from '@/components/app/drawers/NavigationDrawer.vue';
import Uploader from '@/components/ui/Uploader.vue';
import OLevelList from '@/components/level/OLevelList.vue';
import DataTransfer from '@/components/shared/DataTransfer.vue';
import CardWrapper from '@/components/shared/CardWrapper.vue';
import EmptySection from '@/components/shared/EmptySection.vue';
import ListItemSkeleton from '@/components/skeletons/ListItemSkeleton.vue';
import { CONTENT_TYPE_ICON_COLOR, CONTENT_TYPE_ICONS, CONTENT_TYPES } from '@/constants/contentTypes';
import { TASK_TYPE_ICON_COLOR, TASK_TYPE_ICONS, TASK_TYPES } from '@/constants/taskTypes';
import { TRACK_ICON_COLOR, TRACK_TYPES, TRACK_TYPES_ICON } from '@/constants/trackTypes';

export default {
  name: 'TrackManagement',
  components: {
    ListItemSkeleton,
    EmptySection,
    CardWrapper,
    DataTransfer,
    OLevelList,
    Uploader,
    NavigationDrawer,
  },
  inject: ['Names'],
  data() {
    return {
      title: '',
      description: '',
      CONTENT_TYPES,
      CONTENT_TYPE_ICONS,
      CONTENT_TYPE_ICON_COLOR,
      TASK_TYPE_ICON_COLOR,
      TASK_TYPE_ICONS,
      TASK_TYPES,
      TRACK_TYPES,
      TRACK_TYPES_ICON,
      TRACK_ICON_COLOR,
      emptyState: false,
      activeTab: '#appointment',
      poster: '',
      errorPoster: null,
      loadingPoster: false,
      showSaveButtons: true,
      userAvatars: [{
        src: 'https://loremflickr.com/22/22',
        text: 'First',
        icon: '',
      }, {
        src: 'https://loremflickr.com/22/22',
        text: 'Second',
        icon: '',
      }, {
        src: 'https://loremflickr.com/22/22',
        text: 'Third',
        icon: '',
      }],
      groups: [
        {
          id: 1,
          name: 'Продакты',
        },
        {
          id: 2,
          name: 'Проджекты',
        },
        {
          id: 3,
          name: 'Фронт',
        },
        {
          id: 4,
          name: 'Бэк',
        },
        {
          id: 5,
          name: 'Дизигнеры',
        },
      ],
      selected: [],
      trackTypeDraft: true,
    };
  },
  computed: {
    isEditState() {
      return !!this.$route.params.id;
    },
    navigationDrawerTitle() {
      return this.isEditState ? 'Редактирование трека' : 'Создание трека';
    },
  },
  watch: {
    $route: {
      handler(value) {
        if (!value.hash) {
          this.setActiveTab('#main');
          this.$router.replace({ hash: '#main' });
        } else if (value.hash && this.activeTab !== value.hash) {
          this.setActiveTab(value.hash);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initFormCloseListener();
    this.initShowContentFormAddListener();
  },
  beforeDestroy() {
    this.destroyFormCloseListener();
    this.destroyShowContentFormAddListener();
  },
  methods: {
    initFormCloseListener() {
      this.$root.$on('click:close-management', this.redirectToTrackView);
    },
    destroyFormCloseListener() {
      this.$root.$off('click:close-management', this.redirectToTrackView);
    },
    initShowContentFormAddListener() {
      this.$root.$on('click:open-content-form', this.handleShowContentFormAdd);
    },
    destroyShowContentFormAddListener() {
      this.$root.$off('click:open-content-form', this.handleShowContentFormAdd);
    },
    redirectToTrackView() {
      if (this.isEditState) this.$router.push({ name: this.Names.R_TRACK, params: { id: this.$route.params.id } });
      else this.$router.push({ name: this.Names.R_TRACKS });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    uploadPoster() {
      console.log('test');
    },
    deletePoster() {
      console.log('test');
    },
    handleClickAdd(item) {
      const deletedIndex = this.groups.indexOf(item);
      this.groups.splice(deletedIndex, 1);
      this.selected.push(item);
    },
    handleClickRemove(item) {
      const deletedIndex = this.selected.indexOf(item);
      this.selected.splice(deletedIndex, 1);
      this.groups.push(item);
    },
    // TODO Здесь будут потом экшены из апишки
    handleClickSaveDraft() {
      this.$root.$emit('showNotifier', {
        title: 'Сохраним как черновик',
        timeout: 6000,
      });
    },
    // TODO Здесь будут потом экшены из апишки
    handleClickPublish() {
      this.$root.$emit('showNotifier', {
        title: 'Опубликуем сразу, как только сможем',
        timeout: 6000,
      });
    },
    // TODO Здесь будут потом экшены из апишки
    handleClickSave() {
      this.$root.$emit('showNotifier', {
        title: 'Сохраним сразу, как только сможем',
        timeout: 6000,
      });
    },
    // TODO Здесь будет открываться форма добавления контента
    handleShowContentFormAdd() {
      this.$root.$emit('showNotifier', {
        title: 'Формочка добавления контента есть, но обвяжем ее позже',
        timeout: 6000,
      });
    },
    // DELETE Системная кнопка для тестов
    handleClickEmptyState() {
      this.emptyState = !this.emptyState;
    },
    handleClickChangeTrackType() {
      this.trackTypeDraft = !this.trackTypeDraft;
    },
  },
};
</script>
