<template>
  <VContainer :class="{'fill-height' : !items.length}">
    <VRow>
      <VCol>
        <div
          class="tt-text-subtitle"
          data-test="transfer-title"
          :data-test-label="`${dataTestPrefix}-transfer-title`"
        >
          {{ title }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol class="pa-0">
        <VDivider />
      </VCol>
    </VRow>
    <VRow v-if="items.length">
      <VCol
        cols="12"
      >
        <TTTextField
          v-model.trim="search"
          placeholder="Поиск"
          clearable
          :data-test-label="`${dataTestPrefix}-transfer-input-search`"
          large
          append-icon="fal fa-search"
        />
      </VCol>
      <VCol
        v-if="filteredData.length"
        cols="12"
      >
        <VList>
          <template
            v-for="(item,index) in filteredData"
          >
            <VListItem
              :key="index"
              class="px-0"
            >
              <VRow
                align="center"
                dense
              >
                <VCol cols="auto">
                  <VListItemAction class="ma-0">
                    <TTBtn
                      fab
                      color="tt-light-mono-4"
                      :data-test-label="`${dataTestPrefix}-transfer-action-button`"
                      @click="onClickButton(item)"
                    >
                      <VIcon :color="actionIconColor">
                        {{ actionIcon }}
                      </VIcon>
                    </TTBtn>
                  </VListItemAction>
                </VCol>
                <VCol>
                  <VRow no-gutters>
                    <VCol>
                      <VListItemContent>
                        <VListItemTitle :data-test="`${dataTestPrefix}-transfer-item-label`">
                          {{ item.name }}
                        </VListItemTitle>
                      </VListItemContent>
                    </VCol>
                  </VRow>
                  <VRow no-gutters>
                    <VCol>
                      <VDivider
                        :key="`divider-${index}`"
                      />
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
            </VListItem>
          </template>
        </VList>
      </VCol>
    </VRow>
    <VContainer
      v-if="!items.length"
      class="fill-height"
    >
      <VRow justify="center">
        <VCol cols="auto">
          <div
            class="tt-light-mono-46--text tt-text-body-2"
            :data-test="`${dataTestPrefix}-transfer-empty-section`"
          >
            Ничего не выбрано
          </div>
        </VCol>
      </VRow>
    </VContainer>
    <VContainer
      v-if="noSearchResult"
      class="fill-height"
    >
      <VRow justify="center">
        <VCol cols="auto">
          <div
            class="tt-light-mono-46--text tt-text-body-2"
            :data-test="`${dataTestPrefix}-transfer-no-result-section`"
          >
            Ничего не найдено
          </div>
        </VCol>
      </VRow>
    </VContainer>
  </VContainer>
</template>
<script>

export default {
  name: 'DataTransfer',
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    actionIcon: {
      type: String,
      default: 'fal fa-plus',
    },
    actionIconColor: {
      type: String,
      default: 'tt-light-blue',
    },
    dataTestPrefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    filteredData() {
      if (!this.search) return this.items;
      return this.items.filter((item) => item.name.toLowerCase().includes(this.search.toLowerCase()));
    },
    noSearchResult() {
      return this.search && this.filteredData.length === 0;
    },
  },
  methods: {
    onClickButton(item) {
      this.$emit('click:item-action', item);
    },
  },
};
</script>
